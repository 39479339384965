<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Market analysis</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>Market analysis</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/work-processing/2.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">Market analysis</h2>
              <!-- <p>
                We can also help you with bespoke analysis that takes fare data
                to the next level by combining it with other types of data, e.g.
                traffic statistics, load factor data, catchment data.
              </p> -->
              <p>
                From our own experience in airline and airport network planning,
                we know that pricing data is a crucial input to analyse new and
                existing markets.
              </p>
              <p>
                For a holistic view, other aspects may be investigated as well.
                We can help you with this, too, through bespoke analysis. In
                doing so, we take fare data to the next level by combining it
                with other types of data, such as traffic statistics, load
                factors and catchment data.
              </p>
              <p>
                This way, we help you gain new insights to build robust business
                cases. We tailor this advisory service to your needs through
                individual consultation.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "MarketAnalysis",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>